@import url('https://fonts.googleapis.com/css2?family=DynaPuff:wght@400;500&family=Lemonada&family=Poppins&display=swap');

html,
body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif, sans-serif;
}

.App-light {
  color: #fcfcfc;
}

.App-dark {
  color: #232127;
}

p {
  margin: 0;
}

button {
  margin-right: 0.5rem;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.3);
}

.react-date-picker,
.react-time-picker {
  border: none;
  width: 100%;
  box-sizing: border-box;
  margin: 0.25rem 0.25rem;
  padding: 0.5rem 0.5rem;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

.react-date-picker__wrapper,
.react-time-picker__wrapper {
  border: none;
  width: 100%;
}

@keyframes scalePulse {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.loading-logo {
  animation: scalePulse 1000ms ease-in-out infinite alternate;
}

*::-webkit-scrollbar {
  width: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #e46e00;
  border-end-end-radius: 4px;
  border-start-end-radius: 4px;
}
